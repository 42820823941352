.profile {
    padding: 20px;
}

.profile h1 {
    font-size: 2rem;
}

.profile p {
    font-size: 1.2rem;
}
.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .profilePic {
    text-align: center;
  }
  
  /* Hides vertical tabs on small screens */
  @media (max-width: 600px) {
    .profilePic {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .profile-container {
      padding: 0;
      width: 100%;
    }
  
    .MuiTabs-vertical {
      display: none;
    }
  }
  
  