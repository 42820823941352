.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .home-buttons {
    margin-top: 20px;
    display: flex;
    gap: 20px;
  }
  