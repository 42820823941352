.button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    margin-top: 10px;
    text-align: center !important;
    text-decoration: none;
}

.button:hover {
    background-color: #0056b3;
}