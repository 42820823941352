.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
  
  .login-box {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .country-option {
    display: flex;
    align-items: center;
  }
  
  .country-flag {
    margin-right: 8px;
    width: 16px;
    height: 12px;
  }
  
  .select__control {
    height: 56px; /* Match MUI TextField height */
  }
  
  .switch-account-button {
    margin-top: 16px;
  }
  .switch-account-container{
    display: flex;
  }
  .singleInput{
    flex-wrap: nowrap !important;
  }
  .singleInput .oneInput{
    margin: 0 15px;
  }
  
  @media (max-width: 600px) {
    .login-box {
      width: 100%;
      margin: 20px;
    }
  }
  