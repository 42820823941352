.dashboard {
    padding: 20px;
}

.dashboard h1 {
    font-size: 2rem;
}

.dashboard p {
    font-size: 1.2rem;
}
.inputRow {
    display: flex;
    flex-wrap: nowrap !important;
}

.dashboard-container {
    margin-top: 50px;
  }
  
  .dashboard-box {
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .add-button {
    margin-bottom: 20px;
  }
  
  .field-container {
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .text-input,
.number-input {
  margin-bottom: 0px;
}

.subfield-container {
  margin-left: 25px;
  padding-top: 10px;
}

.sub-text-input,
.sub-number-input {
  margin-bottom: 0px;
}

.add-button {
  margin-bottom: 20px;
}
.subInputs{
    box-shadow: 0 0 8px #005f1ea3;
    margin-top: 10px;

}
.highlighted {
  border: 1px solid red;
}

.wrapInput{
    position: relative;
    display: flex;
    flex-direction: column;
}
.startEndDate{
    position: absolute;
    bottom: -3px;
    left: 15px;
    background: #fff;
    padding: 0px;
    color: #1976d2 !important;
    line-height: 1;
}

